import { InterceptorRequestStrategy } from "@/types"
import { AxiosRequestConfig } from "axios"
import store from "../../store/index"

export type PayloadTokenAndRefresh = {
    token: string,
    refreshToken: string
}

export class ConfigureTokenAndRefreshTokenInterceptor implements InterceptorRequestStrategy {
    constructor(private readonly payload: PayloadTokenAndRefresh) {
        this.payload = payload;
    }

    applyInterceptor(config: AxiosRequestConfig): AxiosRequestConfig | null {
        const user = store.getters["auth/getUser"]

        if ( user ) {
            config.headers = {
                token: this.payload.token,
                refreshToken: this.payload.refreshToken
            }

            return config;
        }

        return null
    }
}