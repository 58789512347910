import { PaymentMethod, ShippingAddress } from '@/types'
import { Preferences } from '@capacitor/preferences'

interface State {
	shipping_address: ShippingAddress | null
	paymentInfo: any
	paymentMethod: PaymentMethod | null
	comments: string
}

const state= (): State => ({
	shipping_address: null,
	paymentInfo: null,
	paymentMethod: null,
	comments: "",
})

const getters = {
	getShippingAddress(state: any) {
		return state.shipping_address
	},
	getPaymentMethod(state: any) {
		return state.paymentMethod ? state.paymentMethod : ''
	},
	getPaymentMethodReference(state: any) {
		return state.paymentMethod ? state.paymentMethod.reference : ''
	},
	getPaymentInfo(state: any) {
		return state.paymentInfo
	},
	getComments(state: any) {
		return state.comments
	},
}

const actions = {
	async getPaymentMethod({ state }: any) {
		console.log('getPaymentMethod',!state.paymentMethod)
		if (!state.paymentMethod) {
			const paymentMethod = await Preferences.get({
				key: "paymentMethod",
			})

			state.paymentMethod = paymentMethod && paymentMethod.value ? JSON.parse(paymentMethod.value) : null

			console.log('state.paymentMethod', state.paymentMethod)
		}
	},
	async getPaymentMethodReference({ state }: any) {
		console.log('getPaymentMethodReference',!state.paymentMethodReference)
		if (!state.paymentMethodReference) {
			const paymentMethodReference = await Preferences.get({
				key: "paymentMethodReference",
			})
			state.paymentMethodReference = paymentMethodReference.value
		}
	},
	async getPaymentMethodInfo({ state, commit }: any) {
		if (!state.paymentInfo) {
			const paymentMethodInfo = await Preferences.get({
				key: 'paymentMethodInfo',
			})

			if (paymentMethodInfo.value) {
				commit('setPaymentInfo', JSON.parse(paymentMethodInfo.value))
			}
		}
	},
}

const mutations = {
	setShippingAddress(state: any, shipping_address: ShippingAddress) {
		state.shipping_address = shipping_address
	},
	setPaymentMethod(state: any, payload: PaymentMethod) {
		Preferences.set({
			key: "paymentMethod",
			value: payload ? JSON.stringify(payload) : "",
		})

		Preferences.set({
			key: "paymentMethodReference",
			value: payload ? payload.reference : "",
		})

		state.paymentMethod = payload
	},
	setPaymentInfo(state: any, payload: any) {
		Preferences.set({
			key: 'paymentMethodInfo',
			value: JSON.stringify(payload),
		})

		state.paymentInfo = payload
	},
	setComments(state: any, comments: any) {
		state.comments = comments
	},
	deletePaymentMethod(state: any) {
		state.paymentMethod = ''
	},
	deletePaymentInfo(state: any) {
		state.paymentInfo = null
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
