<template>
	<img v-if="img" :src="img" alt="icon" />
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
	name: 'SbIcon',
	props: {
		icon: {
			type: String || null,
			default: "",
		},
		image: {
			type: String || null,
			default: ""
		}
	},
	setup(props) {
		const img = ref('')
		const getImage = async () => {
			try {
				if (props.icon) {
					img.value = await require("@/assets/icons/" + props.icon)
				} else {
					img.value = props.image
				}
			} catch (e: any) {
				img.value = ''
			}
		}

		onMounted(() => {
			getImage()
		})

		return {
			img,
		}
	},
})
</script>
