import { AxiosRequestConfig } from "axios"
import { InterceptorRequestStrategy, ShippingAddress } from '@/types'
import store from "../../store/index"

/**
 * Si el token es de un Seller, entonces configura el token, el warehouse y los datos del cliente seleccionado,
 * sino solo configura el token y el warehouse.
 */
export class ConfigureCustomerDataSelectedBySeller
	implements InterceptorRequestStrategy
{
	applyInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
		const shippingAddressFixed: ShippingAddress = store.getters['auth/getShippingAddressFixed']
		const customerConfiguredBySeller = store.getters['auth/getCustomerConfiguredBySeller']
		const user = store.getters["auth/getUser"]
		const token: string = store.getters["auth/getToken"]
		const warehouseId = user ? user.warehouseId : undefined
		const reference = store.getters["getReference"]

		let userId = store.getters["auth/getUserId"]
		let tradeTypeId = user ? user.customer.trade_type : undefined
		let userCityId = user ? user.customer.cityId : undefined
		let userStateId = user ? user.customer.stateId : undefined


		if (customerConfiguredBySeller) {
			userId = customerConfiguredBySeller._id
			userCityId = customerConfiguredBySeller.cityId
			userStateId = customerConfiguredBySeller.stateId
			tradeTypeId = customerConfiguredBySeller.tradeTypeId
		}

		if (shippingAddressFixed) {
			userCityId = shippingAddressFixed.cityid || shippingAddressFixed.cityId
			userStateId = shippingAddressFixed.stateid || shippingAddressFixed.stateId
		}

		config.headers = { token }
		config.params = {
			...config.params,
			warehouse: warehouseId,
			reference,
			tradeTypeId,
			userId,
			userCityId,
			userStateId,
		}

		return config
	}
}
